import * as React from 'react'
import {useEffect, useState} from 'react'
import Layout from '../components/Navigation/layout'
import Slideshow from "../components/Slideshow/Slideshow";
import '../theme/main.module.scss';
import {Client, handleApiError} from "../services/ApiService";
import Element from "../components/Element/Element";
import CookieGtag from "../components/Cookies/CookieGtag";
import login from "./login";



type SlideshowItem = {
    ID?: number,
    ClassName?: string,
    Title?: string,
    ImageLink?: string,
    IconLink?: string,
    ButtonPos?: string,
    ButtonEnabled?: number,
    ButtonLinkType?: string,
    ButtonLink?: any,
    ButtonText?: string,
    ButtonPage?: string,
    HolderID?: number,
    ImageID?: number,
    IconID?: number,
    ButtonFileID?: number,
    VideoLink? :string,
    VideoTextImageLink? : string,
}
type Slideshow = {
    ID?: number,
    ClassName?: string,
    Title?: string,
    SlideshowItems?: SlideshowItem
}

type Elements = {
    ID?: number,
    ClassName?: string,
    SortOrder?: number,
    Title?: string,
    Slideshows?: Array<Slideshow>
}

const IndexPage = () => {
    const [elements, setElements] = useState([]);
    const [seoTitle, setSeoTitle] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
    const [seoImage, setSeoImage] = useState('');


    useEffect(() => {
        Client.Raw.get('Page/?filter[URLSegment]=home')
            .then(handleResponse)
            .catch(handleApiError);
    }, []);

    function handleResponse(res) {
        if (res.data && typeof res.data[0] !== "undefined") {
            setSeoTitle(res.data[0].MetaTitle);
            setSeoDescription(res.data[0].MetaDescription);
            setSeoImage(res.data[0].MetaImageLink);
            setElements(res.data[0].Elements ?? []);
        }else {
            console.log("404");
        }
    }

    return (
        <React.StrictMode>
            <Layout pageTitle={seoTitle} description={seoDescription} image={seoImage}>
                {elements.map((data: any, index) => <Element key={index} data={data} pageTitle={seoTitle}/>)}
            </Layout>
        </React.StrictMode>
    )

}



export default IndexPage;
